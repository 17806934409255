<template>
    <div>
        <div v-for="notice in row.item.notices">{{ notice.notice | limit(100) }}</div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>